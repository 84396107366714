<template>
  <ObjectViewLayout
    :title="title"
    :errorStr="errorStr"
    :loading="object == null"
    withBackButton
    @back="$router.push({ name: 'intervention-report', params: { id: $route.params.id } })">
    <template #title-icon-left>
      <BriefcaseIcon />
    </template>

    <template #default>
      <div class="card" style="margin-bottom: 5px;">
        <div class="card-header">
          <span class="title">Pièces examinées</span>
        </div>

        <div
          v-if="schema == null || object == null"
          class="card-body">
          <LoaderView />
        </div>

        <div v-else class="card-body">
          <InfestationReportsForm v-model="object.infestation_reports" />

          <div style="display: flex; justify-content: flex-end;">
            <button
              :class="{ 'loading': savingInfestationReports }"
              :disabled="savingInfestationReports"
              class="btn-secondary"
              @click="saveInfestationReports">
              Enregistrer
            </button>
          </div>
        </div>
      </div>

      <div class="card" style="margin: 5px 0px;">
        <div class="card-header">
          <span class="title">Actions réalisées</span>
        </div>

        <div
          v-if="schema == null || object == null"
          class="card-body">
          <LoaderView />
        </div>

        <div v-else class="card-body">
          <FormField
            :schema="schema.actions_dones"
            hideTitle
            class="form-group">
            <template #default>
              <ActionsForm v-model="object.actions_dones_ids" />
            </template>
          </FormField>
        </div>

        <div style="display: flex; justify-content: flex-end;">
          <button
            :class="{ 'loading': savingActions }"
            :disabled="savingActions"
            class="btn-secondary"
            @click="saveActions">
            Enregistrer
          </button>
        </div>
      </div>

      <div class="card" style="margin: 5px 0px;">
        <div class="card-header">
          <span class="title">Postes</span>
        </div>

        <div
          v-if="schema == null || object == null"
          class="card-body">
          <LoaderView />
        </div>

        <div class="card-body">
          <div
            v-for="(post, index) in object.postes"
            :key="index"
            class="row">
            <div class="form-group col">
              <label class="form-label">Numéro</label>
              <input v-model="post.number" class="form-control" type="number" min="1">
            </div>

            <div class="form-group col">
              <label class="form-label">Nuisible</label>
              <ActivitySelect
                v-model="post.activity_id" />
            </div>

            <div class="form-group col">
              <label class="form-label">Description</label>
              <input v-model="post.description" class="form-control" type="text">
            </div>

            <div class="form-group col">
              <label class="form-label">Etat</label>
              <select v-model="post.state" class="form-select">
                <option
                  value="new">
                  Mise en place
                </option>
                <option
                  value="good">
                  Pas de consommation relevée
                </option>
                <option
                  value="finished">
                  Consommé
                </option>
                <option
                  value="unaccessible">
                  Inaccessible
                </option>
                <option
                  value="gone">
                  Disparu
                </option>
                <option
                  value="deteriorated">
                  Déterioré
                </option>
              </select>
            </div>
            <div class="form-group col" style="flex-grow: 0; display: flex; align-items: flex-end;">
              <button
                class="tool-btn"
                @click="removePost(index)">
                <i class="bi bi-trash" />
              </button>
            </div>
          </div>

          <div>
            <button class="btn-link" @click="addPost()">
              Ajouter un poste
            </button>
          </div>

          <div style="display: flex; justify-content: flex-end;">
            <button
              :class="{ 'loading': savingPostes }"
              :disabled="savingPostes"
              class="btn-secondary"
              @click="savePostes">
              Enregistrer
            </button>
          </div>
        </div>
      </div>

      <div class="card" style="margin: 5px 0px;">
        <div class="card-header">
          <span class="title">Besoin d'améliorations</span>
        </div>

        <div
          v-if="schema == null || object == null"
          class="card-body">
          <LoaderView />
        </div>

        <div v-else class="card-body">
          <FormField
            :schema="schema.improvement_needs"
            hideTitle
            class="form-group">
            <template #default>
              <ImprovementNeedsForm v-model="object.improvement_needs_ids" />
            </template>
          </FormField>

          <div style="display: flex; justify-content: flex-end;">
            <button
              :class="{ 'loading': savingImprovementNeeds }"
              :disabled="savingImprovementNeeds"
              class="btn-secondary"
              @click="saveImprovementNeeds">
              Enregistrer
            </button>
          </div>
        </div>
      </div>

      <div class="card" style="margin: 5px 0px;">
        <div class="card-header">
          <span class="title">Consignes de sécurité / précautions</span>
        </div>

        <div
          v-if="schema == null || object == null"
          class="card-body">
          <LoaderView />
        </div>

        <div v-else class="card-body">
          <FormField
            :schema="schema.safety_rules"
            hideTitle
            class="form-group">
            <template #default>
              <SafetyRulesForm v-model="object.safety_rules_ids" />
            </template>
          </FormField>

          <div style="display: flex; justify-content: flex-end;">
            <button
              :class="{ 'loading': savingSafetyRules }"
              :disabled="savingSafetyRules"
              class="btn-secondary"
              @click="saveSafetyRules">
              Enregistrer
            </button>
          </div>
        </div>
      </div>


      <div class="card" style="margin: 5px 0px;">
        <div class="card-header">
          <span class="title">Observations, remarques,  recommendations et conclusion</span>
        </div>

        <div
          v-if="schema == null || object == null"
          class="card-body">
          <LoaderView />
        </div>

        <div v-else class="card-body">
          <FormField
            :schema="schema.conclusion"
            class="form-group">
            <select v-model="object.conclusion" class="form-select">
              <option
                value="operation_shock_required">
                Opération choc nécessaire
              </option>
              <option
                value="alert">
                Alerte
              </option>
              <option
                value="satisfying">
                Satisfaisant
              </option>
            </select>
          </FormField>

          <FormField
            :schema="schema.observations"
            class="form-group">
            <template #default>
              <textarea
                v-model="object.observations"
                class="form-control" />
            </template>
          </FormField>

          <div style="display: flex; justify-content: flex-end;">
            <button
              :class="{ 'loading': savingObservations }"
              :disabled="savingObservations"
              class="btn-secondary"
              @click="saveObservations">
              Enregistrer
            </button>
          </div>
        </div>
      </div>

      <PdfCard :pdfData="reportPdf" />
    </template>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import FormField from "@/components/forms/FormField.vue";
import BriefcaseIcon from "@/assets/iconsVue/BriefcaseIcon.vue";
import LoaderView from "@/components/LoaderView.vue";
import ActionsForm from "@/components/forms/ActionsForm.vue";
import ImprovementNeedsForm from "@/components/forms/ImprovementNeedsForm.vue";
import SafetyRulesForm from "@/components/forms/SafetyRulesForm.vue";
import InfestationReportsForm from "@/components/forms/InfestationReportsForm.vue";
import ActivitySelect from "@/components/selects/ActivitySelect.vue";
import PdfCard from "@/components/cards/PdfCard.vue";

export default {
  name: "InterventionReportEditionView",
  components: {
    ObjectViewLayout,
    FormField,
    BriefcaseIcon,
    LoaderView,
    ActionsForm,
    ImprovementNeedsForm,
    SafetyRulesForm,
    InfestationReportsForm,
    ActivitySelect,
    PdfCard,
  },
  data() {
    return {
      object: null,
      schema: null,
      errorStr: null,

      savingImprovementNeeds: false,
      savingSafetyRules: false,
      savingPostes: false,
      savingInfestationReports: false,
      savingObservations: false,
      savingActions: false,
      
      reportPdf: null,
    };
  },
  computed: {
    title() {
      if (!this.object) {
        return "Chargement de l'intervention...";
      } else {
        return "Rapport d'intervention";
      }
    },
  },
  mounted() {
    Promise.all([
      this.fetchObject(),
      this.fetchSchema(),
      this.fetchReportPdf(),
    ]).catch((err) => {
      this.errorStr = "Une erreur est survenue lors du chargement de l'intervention";
      throw err;
    });
  },
  methods: {
    fetchObject() {
      return this.$services.intervention.retrieve(this.$route.params.id)
        .then((response) => {
          this.object = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.status == 404) {
            this.errorStr = "L'intervention n'existe pas ou vous n'avez pas les droits pour la consulter. Contactez le Webmaster si vous pensez qu'il s'agit d'une erreur.";
          } else {
            throw error;
          }
        });
    },
    fetchSchema() {
      return this.$services.intervention.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
    fetchReportPdf() {
      return this.$services.intervention.fetchReportPdf(this.$route.params.id)
        .then((response) => {
          this.reportPdf = response.data;
        });
    },
    addPost() {
      this.object.postes.push(
        {
          "number": this.object.postes.length + 1,
          "activity_id": null,
          "description": "",
          "state": "new",
        },
      );
    },
    removePost(index) {
      this.object.postes.splice(index, 1);
    },

    saveInfestationReports() {
      this.savingInfestationReports = true;

      const data = {
        infestation_reports: this.object.infestation_reports,
      };

      this.$services.intervention.patch(this.object.id, data)
        .then((response) => {
          this.object.infestation_reports = response.data.infestation_reports;

        }).finally(() => {
          this.savingInfestationReports = false;
        });
    },

    saveActions() {
      this.savingActions = true;

      const data = {
        actions_dones_ids: this.object.actions_dones_ids,
      };

      this.$services.intervention.patch(this.object.id, data)
        .then((response) => {
          this.object.actions_dones_ids = response.data.actions_dones_ids;

        }).finally(() => {
          this.savingActions = false;
        });
    },

    saveImprovementNeeds() {
      this.savingImprovementNeeds = true;

      const data = {
        improvement_needs_ids: this.object.improvement_needs_ids,
      };

      this.$services.intervention.patch(this.object.id, data)
        .then((response) => {
          this.object.improvement_needs_ids = response.data.improvement_needs_ids;

        }).finally(() => {
          this.savingImprovementNeeds = false;
        });
    },

    saveObservations() {
      this.savingObservations = true;

      const data = {
        observations: this.object.observations,
        conclusion: this.object.conclusion,
      };

      this.$services.intervention.patch(this.object.id, data)
        .then((response) => {
          this.object.observations = response.data.observations;
          this.object.conclusion = response.data.conclusion;

        }).finally(() => {
          this.savingObservations = false;
        });
    },

    saveSafetyRules() {
      this.savingSafetyRules = true;

      const data = {
        safety_rules: this.object.safety_rules_ids,
      };

      this.$services.intervention.patch(this.object.id, data)
        .then((response) => {
          this.object.safety_rules = response.data.safety_rules;

        }).finally(() => {
          this.savingSafetyRules = false;
        });
    },

    savePostes() {
      this.savingPostes = true;

      const data = {
        postes: this.object.postes,
      };

      this.$services.intervention.patch(this.object.id, data)
        .then((response) => {
          this.object.postes = response.data.postes;

        }).finally(() => {
          this.savingPostes = false;
        });
    },
  },
};
</script>