<template>
  <Modal ref="modal">
    <PersonForm
      v-if="person"
      v-model="person" />

    <template #buttons>
      <button
        class="btn-secondary"
        :disabled="loading"
        @click="hide">
        Annuler
      </button>
      <button
        class="btn-primary"
        :class="{ 'loading': loading }"
        :disabled="loading || !canSubmit"
        style="margin-left: 5px;"
        @click="submit">
        Enregistrer
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import PersonForm from "@/components/forms/PersonForm.vue";

export default {
  name: "PersonModal",
  components: {
    Modal,
    PersonForm,
  },
  emits: ["onPersonCreated", "onPersonChanged"],
  data() {
    return {
      person: null,
      loading: false,
    };
  },
  computed: {
    canSubmit() {
      return this.person && this.person.last_name;
    },
  },
  methods: {
    edit(person) {
      if (person.id == null) {
        throw new Error("Person id is required");
      }

      this.loading = false;

      this.person = {
        ...person,
      };

      this.$refs.modal.show();
    },
    new() {
      this.loading = false;

      this.person = {
        title: "mr",
        language: "fr",
      };
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    submit() {
      this.loading = true;

      if (this.person.id) {
      
        this.$services.person.patch(
          this.person.id,
          {
            title: this.person.title,
            last_name: this.person.last_name,
            first_name: this.person.first_name,
            email: this.person.email,
            phone_number: this.person.phone_number,
            language: this.person.language,
            notes: this.person.notes,
          },
        )
          .then((response) => {
            this.$emit("onPersonChanged", response.data);
            this.hide();
          });
        
      } else {
        
        this.$services.person.create(
          {
            title: this.person.title,
            last_name: this.person.last_name,
            first_name: this.person.first_name,
            email: this.person.email,
            phone_number: this.person.phone_number,
            language: this.person.language,
            notes: this.person.notes,
          },
        )
          .then((response) => {
            this.$emit("onPersonCreated", response.data);
            this.hide();
          });
      }
    },
  },
    
};

</script>

<style lang="scss" scoped>


</style>