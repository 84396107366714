<template>
  <button class="tool-btn tooltip" @click.stop="createIntervention">
    <i class="bi bi-briefcase" />
    <div class="tooltip-text">
      Nouvelle intervention
    </div>
  </button>

  <button class="tool-btn tooltip" @click.stop="createQuote">
    <InvoiceIcon status="quote" />
    <div class="tooltip-text">
      Nouveau devis
    </div>
  </button>

  <button class="tool-btn tooltip" @click.stop="createInvoice">
    <InvoiceIcon status="invoice" />
    <div class="tooltip-text">
      Nouvelle facture
    </div>
  </button>

  <button class="tool-btn tooltip" @click.stop="createContract">
    <InvoiceIcon status="contract" />
    <div class="tooltip-text">
      Nouveau contrat
    </div>
  </button>
  
  <button
    class="tool-btn tooltip"
    :disabled="modelValue.main_email == null"
    @click.stop="$refs.sendEmailModal.show()">
    <i class="bi bi-envelope-at" />
    <div class="tooltip-container">
      <div class="tooltip-text">
        Envoyer un message par email
      </div>
      <div
        v-if="modelValue.main_email == null"
        class="tooltip-text-red">
        Aucune adresse email renseignée
      </div>
    </div>
    <SendEmailToCustomerModal ref="sendEmailModal" :customer="modelValue" />
  </button>

  <button
    class="tool-btn tooltip"
    @click.stop="$refs.interventionsRecapExportModal.show(modelValue)">
    <i class="bi bi-file-earmark-post" />
    <div class="tooltip-container">
      <div class="tooltip-text">
        Exporter le récapitulatif
      </div>
    </div>
  </button>

  <InterventionsRecapExportPdfModal ref="interventionsRecapExportModal" @click.stop />

  <CreateContractModal
    ref="createContractModal"
    @created="onContractCreated" />
</template>

<script>

import SendEmailToCustomerModal from "@/components/modals/SendEmailToCustomerModal.vue";
import InvoiceIcon from "@/assets/iconsVue/InvoiceIcon.vue";
import CreateContractModal from "@/components/modals/CreateContractModal.vue";
import InterventionsRecapExportPdfModal from "@/components/modals/InterventionsRecapExportPdfModal.vue";

export default {
  name: "CustomerToolButtons",
  components: {
    SendEmailToCustomerModal,
    InvoiceIcon,
    CreateContractModal,
    InterventionsRecapExportPdfModal,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    createIntervention() {
      this.$router.push({ name: "funnel.intervention", params: { mode: "intervention", customerId: this.modelValue.id } });
    },
    createQuote() {
      this.$router.push({ name: "funnel.quote", params: { customerId: this.modelValue.id } });
    },
    createInvoice() {
      this.$router.push({ name: "funnel.invoice", params: { customerId: this.modelValue.id } });
    },
    createContract() {
      this.$refs.createContractModal.show(this.modelValue);
    },
    onContractCreated(contract) {

      this.$services.invoice.patch(
        this.modelValue.id,
        {
          contract_id: contract.id,
        })
        .then((response) => {
          this.$emit(
            "update:modelValue",
            response.data,
          );
        });
    },
  },
};

</script>
