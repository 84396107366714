<template>
  <ObjectViewLayout
    title="Rechercher un créneau">
    <template #leftBar>
      <div class="vertical-spaced-container">
        <div class="card">
          <div class="card-header">
            <span class="title">Activités</span>
          </div>

          <div class="card-body">
            <FormField
              hideTitle
              class="form-group col-sm">
              <template #default>
                <ActivityForm v-model="activityId" />
              </template>
            </FormField>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <span class="title">Adresse</span>
          </div>

          <div class="card-body">
            <div class="form-group">
              <VueGoogleAutocomplete
                id="address-search"
                :country="countryCodes"
                type="text"
                placeholder="Recherchez une adresse"
                class="form-control"
                @placechanged="onAddressSuggestion" />
            </div>

            <div class="row">
              <FormField
                title="Code postal"
                class="col-sm"
                style="max-width: 300px;">
                <input v-model="zipcode" type="text" class="form-control">
              </FormField>

              <FormField
                title="Pays"
                class="col-sm"
                style="max-width: 300px;">
                <CountrySelect v-model="countryCode" class="form-select" />
              </FormField>
            </div>
          </div>
        </div>

        <div style="position: fixed; bottom: 10px; width: 100%; z-index: 10; display: flex; justify-content: center;">
          <div class="card" style="box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 10px 0px;">
            <div class="card-body">
              <button
                class="btn btn-primary"
                :disabled="activity == null || zipcode == null || countryCode == null || (activityId == $route.query.activite && zipcode == $route.query.code_postal && countryCode == $route.query.pays)"
                @click="fetchSlots">
                <span v-if="$route.query.activite == null || $route.query.code_postal == null || $route.query.pays == null">
                  Rechercher des créneaux
                </span>
                <span v-else>
                  Appliquer les filtres
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="card">
      <div class="card-header">
        <span class="title">Créneaux disponibles</span>
      </div>

      <div
        v-if="$route.query.activite == null || $route.query.code_postal == null || $route.query.pays == null"
        class="card-body">
        <i>Commencez par sélectionner une activité, un code postal et un pays</i>
      </div>

      <div v-else class="card-body">
        <FreeSlots
          :extendable="true"
          :activityIds="[$route.query.activite]"
          :zipcode="$route.query.code_postal"
          :countryCode="$route.query.pays"
          @extend="showUserDayPlanningAtSlot" />
      </div>
    </div>

    <template #rightBar>
      <div
        style="height: 80vh; position: sticky; top: 10px; display: flex;">
        <UserDayPlanning
          v-if="eventsUser && eventsDate"
          :user="eventsUser"
          :date="eventsDate"
          :events="events"
          :highlightedStartDatetime="highlightedStartDatetime"
          :highlightedEndDatetime="highlightedEndDatetime"
          showUser
          showDate
          showTimeScale />
      </div>
    </template>
  </ObjectViewLayout>
</template>

<script>
import UserDayPlanning from "@/components/calendar/UserDayPlanning.vue";

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import FormField from "@/components/forms/FormField.vue";
import ActivityForm from "@/components/forms/ActivityForm.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import CountrySelect from "@/components/inputs/CountrySelect.vue";
import FreeSlots from "@/views/interventions/FreeSlots.vue";
import { useActivitiesStore } from "@/stores/activities";
import { favoriteCountries } from "@/countries";

export default {
  name: "QuickSlotSearchView",
  components: {
    ObjectViewLayout,
    FormField,
    ActivityForm,
    VueGoogleAutocomplete,
   
    CountrySelect,
    FreeSlots,
    UserDayPlanning,
  },
  beforeRouteLeave() {
    if (this.canSaveCall) {
      this.saveCall();
    }
  },
  data() {
    return {
      activitiesStore: useActivitiesStore(),

      activityId: this.$route.query.activite,
      zipcode: this.$route.query.code_postal,
      countryCode: this.$route.query.pays ? this.$route.query.pays : "be",

      // Events to display in the calendar
      eventsUser: null,
      eventsDate: null,
      events: undefined,
      highlightedStartDatetime: null,
      highlightedEndDatetime: null,
    };
  },
  computed: {
    activity() {
      if (this.activityId) {
        return this.activitiesStore.getActivityById(this.activityId);
      } else {
        return null;
      }
    },
    countryCodes() {
      return Object.keys(favoriteCountries);
    },
    
  },
  methods: {
    onAddressSuggestion(addressData) {
      this.zipcode = addressData.postal_code;
      this.countryCode = this.formatCountry(addressData.country);
    },
    formatCountry(country) {
      switch (country.toLowerCase()) {
      case "belgique":
        return "be";
      case "belgium":
        return "belgique";
      case "luxembourg":
        return "lu";
      case "france":
        return "fr";
      case "pays-bas":
        return "nl";
      }
      throw new Error("Google Autocomplete returned unknown country: " + country);
    },
    fetchSlots() {
      this.$router.push(
        {
          name: this.$route.name,
          query: {
            activite: this.activityId,
            code_postal: this.zipcode,
            pays: this.countryCode,
          },
        },
      );
    },
    showUserDayPlanningAtSlot(slot) {
      console.log(slot);
      this.eventsUser = slot.user;
      this.eventsDate = slot.previous_planning_event ? slot.previous_planning_event.end_date : slot.next_planning_event.start_date;
      this.events = undefined;
      this.highlightedStartDatetime = slot.previous_planning_event ? slot.previous_planning_event.end_date + "T" + slot.previous_planning_event.end_time : null;
      this.highlightedEndDatetime = slot.next_planning_event ? slot.next_planning_event.start_date + "T" + slot.next_planning_event.start_time : null;

      this.$services.planning.fetchPlanning(
        this.eventsDate,
        this.eventsDate,
        this.eventsUser.id,
      )
        .then((response) => {
          this.events = response.data;
        });
    },
  },
};
</script>



<style scoped lang="scss">

.buttons-container {
  position: sticky;
  bottom: 0px;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 10px 0px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

</style>