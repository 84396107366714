<template>
  <ObjectViewLayout
    :title="title"
    :error="errorFetchingObject">
    <div class="vertical-spaced-container">
      <div class="flow-spaced-container" style="align-items: center,">
        <div>
          <div class="form-label">
            Afficher les créneaux depuis
          </div>
          <input
            v-model="dateFrom"
            type="date"
            class="form-control">
        </div>

        <div>
          <div class="form-label">
            Afficher les créneaux jusqu'à
          </div>
          <input
            v-model="dateTo"
            type="date"
            class="form-control">
        </div>
      </div>

      <div class="slot-filter-bar">
        <div class="flow-spaced-container" style="align-items: center,">
          <button
            class="btn-link"
            @click="extendFilterBar = !extendFilterBar">
            {{ extendFilterBar ? 'Voir moins de filtres' : 'Voir plus de filtres' }}
          </button>
          <button v-if="extendFilterBar" class="btn-secondary" style="margin-left: auto;" @click="$refs.freeSlots.fetchSlots()">
            Appliquer les filtres
          </button>
        </div>

        <div class="slot-filter-bar-collapsable" :class="{ 'visible': extendFilterBar }">
          <div class="flow-spaced-container">
            <div class="form-group">
              <label class="form-label">
                Filtrer par technicien
              </label>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :checked="selectAllTechnicians"
                  @change="selectAllTechnicians = $event.target.checked">
                <label class="form-check-label">
                  Tous
                </label>
              </div>
              <div
                v-for="(technician, index) in technicians"
                :key="index"
                class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :checked="selectedTechniciansIds.includes(technician.id) || selectAllTechnicians"
                  @change="selectTechnicianId(technician.id, $event.target.checked)">
                <label class="form-check-label">
                  {{ $filters.fullname(technician) }}
                </label>
              </div>
            </div>

            <div class="form-group">
              <label class="form-label">
                Filtrer par activité
              </label>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :checked="selectAllActivities"
                  @change="selectAllActivities = $event.target.checked">
                <label class="form-check-label">
                  Tous
                </label>
              </div>
              <div
                v-for="(activity, index) in activities"
                :key="index"
                class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :checked="selectedActivitiesIds.includes(activity.id) || selectAllActivities"
                  @change="selectActivityId(activity.id, $event.target.checked)">
                <label class="form-check-label">
                  {{ activity.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FreeSlots
        v-if="object"
        ref="freeSlots"
        :extendable="true"
        :technicianIds="selectedTechniciansIds"
        :activityIds="selectedActivitiesIds"
        :dateFrom="dateFrom"
        :zipcode="object.location.zipcode"
        :countryCode="object.location.country_code"
        :dateTo="dateTo"
        :intervention="object"
        @extend="showUserDayPlanningAtSlot" />
    </div>

    <template #leftBar>
      <div
        v-if="interventionCoordinates"
        style="position: sticky; top: 10px;">
        <GoogleMap
          :markers="[{ lat: interventionCoordinates[0], lng: interventionCoordinates[1] }]" />
      </div>
    </template>

    <template #rightBar>
      <div
        v-if="object"
        style="height: 80vh; position: sticky; top: 10px; display: flex;">
        <UserDayPlanning
          v-if="eventsUser && eventsDate"        
          :user="eventsUser"
          :date="eventsDate"
          :events="events"
          :highlightedStartDatetime="highlightedStartDatetime"
          :highlightedEndDatetime="highlightedEndDatetime"
          showUser
          showDate
          showTimeScale />
      </div>
    </template>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import { useTechniciansStore } from "@/stores/technicians";
import { useActivitiesStore } from "@/stores/activities";
import UserDayPlanning from "@/components/calendar/UserDayPlanning.vue";
import GoogleMap from "@/components/vueComponents/google/GoogleMap.vue";
import FreeSlots from "@/views/interventions/FreeSlots.vue";

export default {
  name: "InterventionObjectMakeAppointment",
  components: {
    ObjectViewLayout,
    UserDayPlanning,
    GoogleMap,
    FreeSlots,
  },
  data() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() -1);
    const in10Days = new Date();
    in10Days.setDate(in10Days.getDate() + 10);
      
    return {
      // Data
      object: null,
      interventionCoordinates: null,

      // States
      errorFetchingObject: false,
      extendFilterBar: false,

      // Filters
      
      dateFrom: yesterday.toISOString().split("T")[0],
      dateTo: in10Days.toISOString().split("T")[0],
      selectedTechniciansIds: [],
      selectAllTechnicians: true,
      selectedActivitiesIds: [],
      selectAllActivities: true,

      // Stores
      techniciansStore: useTechniciansStore(),
      activitiesStore: useActivitiesStore(),

      // Events to display in the calendar
      eventsUser: null,
      eventsDate: null,
      events: undefined,
      highlightedStartDatetime: null,
      highlightedEndDatetime: null,
    };
  },
  computed: {
    technicians() {
      return this.techniciansStore.getTechnicians;
    },
    activities() {
      return this.activitiesStore.getActivities;
    },
    title() {
      if (this.object) {
        return `Fixer un rendez-vous pour l'intervention au ${this.object.location.street_number ? this.object.location.street_number : ""} ${this.object.location.street} ${this.object.location.zipcode} ${this.object.location.city}`;
      } else {
        return "Fixer un rendez-vous pour l'intervention";
      }
    },
  },
  watch: {
    dateFrom() {
      this.$refs.freeSlots.fetchSlots();
    },
    dateTo() {
      this.$refs.freeSlots.fetchSlots();
    },
  },
  mounted() {
    this.fetchObject();
  },
  methods: {
    fetchObject() {
      this.$services.intervention.retrieve(this.$route.params.id)
        .then((response) => {
          this.object = response.data;
          this.fetchInterventionCoordinates();
        })
        .catch((err) => {
          this.errorFetchingObject = true;
          throw err;
        });
    },
    fetchInterventionCoordinates() {
      if (this.object == null || this.object.location == null) {
        throw new Error("Object or object location is null");
      }

      this.$services.location.fetchCoordinates(this.object.location.id)
        .then((response) => {
          this.interventionCoordinates = response.data;
        })
        .catch((err) => {
          this.errorFetchingObject = true;
          throw err;
        });
    },
    selectTechnicianId(id, checked) {
      if (checked) {
        this.selectedTechniciansIds.push(id);
      } else {
        this.selectedTechniciansIds = this.selectedTechniciansIds.filter((technicianId) => technicianId != id);
      }
    },
    selectActivityId(id, checked) {
      if (checked) {
        this.selectedActivitiesIds.push(id);
      } else {
        this.selectedActivitiesIds = this.selectedActivitiesIds.filter((activityId) => activityId != id);
      }
    },
    showUserDayPlanningAtSlot(slot) {
      this.eventsUser = slot.user;
      this.eventsDate = slot.previous_planning_event ? slot.previous_planning_event.end_date : slot.next_planning_event.start_date;
      this.events = undefined;
      this.highlightedStartDatetime = slot.previous_planning_event ? slot.previous_planning_event.end_date + "T" + slot.previous_planning_event.end_time : null;
      this.highlightedEndDatetime = slot.next_planning_event ? slot.next_planning_event.start_date + "T" + slot.next_planning_event.start_time : null;

      this.$services.planning.fetchPlanning(
        this.eventsDate,
        this.eventsDate,
        this.eventsUser.id,
      )
        .then((response) => {
          this.events = response.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>

  .slot-filter-bar {

    .slot-filter-bar-collapsable {
      overflow: hidden;
      transition: height 0.3s ease-in-out;
      height: 0px;

      &.visible {
        height: 450px;
      }
    }
  }
  
</style>
