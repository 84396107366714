<template>
  <ObjectViewLayout
    :title="title"
    :loading="object == null"
    :error="errorFetching"
    withBackButton
    @back="$router.push({ name: 'customer-list' })">
    <template #title-icon-left>
      <i class="bi bi-person" />
    </template>

    <template #title-right>
      <div v-if="object.total_billed != null" class="tag tooltip">
        <i class="bi bi-cash-stack" /> {{ object.total_billed }}€ facturé
        <div class="tooltip-text">
          Seul un admin peut voir ça
        </div>
      </div>
    </template>

    <template #rightButton>
      <CustomerToolButtons v-model="object" />
    </template>

    <div class="vertical-spaced-container">
      <div>
        <div class="flow-spaced-container fill">
          <div class="card">
            <div class="card-header">
              <span class="title" style="margin-right: 5px;">Facturation</span>
              <button class="small-tool-btn" @click="$refs.editBillingInfoModal.show(object)">
                <i class="bi bi-pencil-square" />
              </button>
            </div>
            <div class="card-body">
              <div
                class="info-grid-layout">
                <div>Nom</div>
                <div>{{ $filters.capitalize(object.billing_person_title) }} {{ $filters.capitalize(object.billing_person_first_name) }} {{ $filters.capitalize(object.billing_person_last_name) }}</div>
                <div>Société</div>
                <div>{{ object.billing_company_name }} {{ object.billing_company_vat }}</div>
                <div>Adresse</div>
                <div>{{ object.billing_street_number }} {{ object.billing_street }} {{ object.billing_bp }}<br>{{ object.billing_zipcode }} {{ object.billing_city }} {{ object.billing_country_code }}</div>
              </div>
              <EditCustomerBillingInfoModal ref="editBillingInfoModal" @change="onBillingInfoChanged" />
            </div>
          </div>

          <CustomerPersonsCard v-model="object" />
          
          <LocationsCard v-model="object.locations" />

          <ContractCompactCard v-model="object" />
        </div>
      </div>

      <div class="horizontal-spaced-container">
        <InvoiceCompactCard
          v-for="invoice of object.invoices.slice(0, 2)"
          :key="invoice.id"
          :modelValue="invoice" />

        <button
          v-if="object.invoices.length > 2"
          class="btn-link"
          @click="$refs.customerInvoicesModal.show()">
          Voir {{ object.invoices.length - 2 }} factures de plus
        </button>

        <CustomerInvoicesModal ref="customerInvoicesModal" :invoices="object.invoices" />

        <div>
          <div class="card">
            <div class="card-header">
              <span class="title">Historique des interventions</span>
            </div>

            <div class="card-body" style="background: #e6e6e6; padding: 10px;">
              <div
                v-for="intervention of object.interventions.slice(0, 3)"
                :key="intervention.id">
                <time :datetime="intervention.created_at" class="text-gray-700 text-xs">
                  Créé le {{ $filters.dateStringToHumanizedDateTime(intervention.created_at, "short") }}
                </time>
                
                <InterventionCompactCard
                  style="margin-top: 5px; margin-bottom: 10px;"
                  :modelValue="intervention" />
              </div>
              
              <button
                v-if="object.interventions.length > 3"
                class="btn-link"
                @click="$refs.customerInterventionsModal.show()">
                Voir {{ object.interventions.length - 3 }} interventions de plus
              </button>

              <CustomerInterventionsModal ref="customerInterventionsModal" :interventions="object.interventions" />
            </div>
          </div>
        </div>

        <div>
          <div class="card">
            <div class="card-header">
              <span class="title">Historique des devis</span>
            </div>

            <div class="card-body" style="background: #e6e6e6; padding: 10px;">
              <div
                v-for="quote of object.quotes"
                :key="quote.id">
                <div>
                  <time :datetime="quote.created_at" class="text-xs text-gray-700">
                    Créé le {{ $filters.dateStringToHumanizedDateTime(quote.created_at, "short") }}
                  </time>
                </div>
                <QuoteCompactCard
                  style="margin-top: 5px; margin-bottom: 10px;"
                  :modelValue="quote" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <span class="title">Tout l'historique</span>
        </div>
      </div>

      <ul class="space-y-6" style="margin-bottom: 10px;">
        <li
          v-for="(date, index) in dates"
          :key="index"
          class="relative flex gap-x-4">
          <div v-if="index != dates.length - 1" class="absolute left-0 flex w-6 justify-center" style="top: 20px; height: 100%;">
            <div class="w-px bg-gray-500 text-gray-700" />
          </div>
          <div class="relative flex h-6 w-6 flex-none items-center justify-center">
            <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
          </div>

          <div class="flex-auto">
            <div class="flex justify-between gap-x-4">
              <time :datetime="date.date" class="flex-none py-0.5 text-xs leading-5 text-gray-700">{{ $filters.dateStringToHumanizedDateTime(date.date, "short") }}</time>
            </div>
            <div class="flow-spaced-container">
              <div v-for="(relatedObject, objIndex) in date.objects" :key="objIndex">
                <div v-if="relatedObject.type === 'intervention'">
                  <InterventionCompactCard :modelValue="relatedObject" />
                </div>
                <div v-else-if="relatedObject.type === 'invoice'">
                  <InvoiceCompactCard :modelValue="relatedObject" />
                </div>
                <div v-else-if="relatedObject.type === 'quote'">
                  <QuoteCompactCard :modelValue="relatedObject" />
                </div>
                <div v-else-if="relatedObject.type === 'call'">
                  <CustomerCallCompactRow
                    :object="relatedObject" />
                </div>
                <div v-else-if="relatedObject.type === 'email'">
                  <CustomerOvhEmail :ovhEmail="relatedObject" />
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <template #rightBar>
      <div class="vertical-spaced-container">
        <div class="card">
          <div class="card-header">
            <span class="title">Notifications</span>
          </div>

          <div class="card-body">
            <SimpleButton
              v-if="object.send_rdv_confirmation_email"
              text="Désactiver les confirmations de RDV par email"
              icon="bi bi-bell-fill"
              :loading="togglingSendRdvConfirmationEmail"
              @click.stop="toggleSendRdvConfirmationEmail" />

            <SimpleButton
              v-else
              text="Activer les confirmations de RDV par email"
              icon="bi bi-bell-slash"
              :loading="togglingSendRdvConfirmationEmail"
              @click.stop="toggleSendRdvConfirmationEmail" />

            <SimpleButton
              v-if="object.send_payment_requests"
              text="Désactiver les demande de paiement"
              icon="bi bi-bell-fill"
              :loading="togglingSendPaymentRequests"
              @click.stop="toggleSendPaymentRequests" />

            <SimpleButton
              v-else
              text="Activer les demande de paiement"
              icon="bi bi-bell-slash"
              :loading="togglingSendPaymentRequests"
              @click.stop="toggleSendPaymentRequests" />

            <SimpleButton
              v-if="object.send_satisfaction_form"
              text="Désactiver sms de satisfaction"
              icon="bi bi-bell-fill"
              :loading="togglingSendSatisfactionForms"
              @click.stop="toggleSendSatisfactionForms" />

            <SimpleButton
              v-else
              text="Activer sms de satisfaction"
              icon="bi bi-bell-slash"
              :loading="togglingSendSatisfactionForms"
              @click.stop="toggleSendSatisfactionForms" />
          </div>
        </div>

        <RemarkForm
          v-if="object.remarks"
          :remarks="object.remarks" />

        <HistoryCard
          objectName="le client"
          :history="object.history"
          :postComment="postComment" />
      </div>
    </template>
  </ObjectViewLayout>
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import CustomerToolButtons from "@/components/toolbuttons/CustomerToolButtons.vue";
import RemarkForm from "@/components/forms/RemarkForm.vue";
import HistoryCard from "@/components/cards/HistoryCard.vue";
import InterventionCompactCard from "@/components/cards/interventionCards/InterventionCompactCard.vue";
import InvoiceCompactCard from "@/components/cards/invoiceCards/InvoiceCompactCard.vue";
import QuoteCompactCard from "@/components/cards/quoteCards/QuoteCompactCard.vue";
import CustomerCallCompactRow from "@/components/rows/customerCall/CustomerCallCompactRow.vue";
import CustomerOvhEmail from "@/components/cards/CustomerOvhEmailCard.vue";
import LocationsCard from "@/components/cards/LocationsCard.vue";
import CustomerPersonsCard from "@/components/cards/CustomerPersonsCard.vue";
import EditCustomerBillingInfoModal from "@/components/modals/EditCustomerBillingInfoModal.vue";
import CustomerInvoicesModal from "@/components/modals/CustomerInvoicesModal.vue";
import CustomerInterventionsModal from "@/components/modals/CustomerInterventionsModal.vue";
import ContractCompactCard from "@/components/cards/contractCards/ContractCompactCard.vue";
import SimpleButton from "@/components/vueComponents/button/SimpleButton.vue";


export default {
  name: "CustomerObjectView",
  components: {
    ObjectViewLayout,
    CustomerToolButtons,
    CustomerOvhEmail,
    RemarkForm,
    InvoiceCompactCard,
    QuoteCompactCard,
    HistoryCard,
    InterventionCompactCard,
    CustomerCallCompactRow,
    LocationsCard,
    CustomerPersonsCard,
    EditCustomerBillingInfoModal,
    CustomerInvoicesModal,
    CustomerInterventionsModal,
    ContractCompactCard,
    SimpleButton,
  },
  data() {
    return {
      object: null,
      errorFetching: false,
      subjectOfMessageToSend: null,
      textOfMessageToSend: null,
      dates: [],

      togglingSendRdvConfirmationEmail: false,
      togglingSendPaymentRequests: false,
      togglingSendSatisfactionForms: false,
    };
  },
  computed: {
    title() {
      if (!this.object) {
        return null;
      }
      return this.$filters.printCustomer(this.object);
    },
  },
  mounted() {
    this.fetchObject();
  },
  methods: {
    fetchObject() {
      this.$services.customer.retrieve(this.$route.params.id)
        .then((response) => {
          this.object = response.data;

          this.dates = this.object.interventions
            .map((intervention) => {
              return {
                date: intervention.rdv_date ? intervention.rdv_date : intervention.created_at,
                type: "intervention",
                ...intervention,
              };
            })
            .concat(
              this.object.futures_interventions.map((intervention) => {
                return {
                  date: intervention.invoice.created_at,
                  type: "intervention",
                  ...intervention,
                };
              }),
            )
            .concat(
              this.object.other_invoices.map((invoice) => {
                return {
                  date: invoice.created_at,
                  type: "invoice",
                  ...invoice,
                };
              }),
            )
            .concat(
              this.object.other_quotes.map((quote) => {
                return {
                  date: quote.created_at,
                  type: "quote",
                  ...quote,
                };
              }),
            )
            .concat(
              this.object.calls.map((call) => {
                return {
                  date: call.created_at,
                  type: "call",
                  ...call,
                };
              }),
            )
            .concat(
              this.object.emails.map((email) => {
                return {
                  date: email.date,
                  type: "email",
                  ...email,
                };
              }),
            )
            .reduce((acc, object) => {
              const date = object.date.split("T")[0];
              const index = acc.findIndex((el) => el.date === date);
              if (index === -1) {
                acc.push({ date, objects: [object] });
              } else {
                acc[index].objects.push(object);
              }
              return acc;
            }, [])
            .sort((a, b) => {
              return new Date(b.date) - new Date(a.date);
            });



        })
        .catch((err) => {
          this.errorFetching = true;
          throw err;
        });
    },
    cancel() {
      this.$router.push({ name: "customer-list" });
    },
    addBillingAddress() {
      this.object.billing_address = {};
    },
    postComment(comment) {
      return this.$services.customer.addComment(this.object.id, comment)
        .then((response) => {
          this.object.history = response.data.history;
        });
    },
    onLocationChanged(newLocation) {
      const index = this.object.locations.findIndex((location) => location.id === newLocation.id);
      this.object.locations[index] = newLocation;
    },
    onBillingInfoChanged(info) {
      this.object = {
        ...this.object,
        ...info,
      };
    },
    toggleSendRdvConfirmationEmail() {
      this.togglingSendRdvConfirmationEmail = true;

      this.$services.customer.toggleSendRdvConfirmationEmail(
        this.object.id,
      )
        .then((customer) => {
          this.object = {
            ...this.object,
            send_rdv_confirmation_email: customer.send_rdv_confirmation_email,
          };

        }).finally(() => {
          this.togglingSendRdvConfirmationEmail = false;
        });
    },
    toggleSendPaymentRequests() {
      this.togglingSendPaymentRequests = true;

      this.$services.customer.toggleSendPaymentRequests(
        this.object.id,
      )
        .then((customer) => {
          this.object = {
            ...this.object,
            send_payment_requests: customer.send_payment_requests,
          };

        }).finally(() => {
          this.togglingSendPaymentRequests = false;
        });
    },
    toggleSendSatisfactionForms() {
      this.togglingSendSatisfactionForms = true;

      this.$services.customer.toggleSendSatisfactionForms(
        this.object.id,
      )
        .then((customer) => {
          this.object = {
            ...this.object,
            send_satisfaction_form: customer.send_satisfaction_form,
          };

        }).finally(() => {
          this.togglingSendSatisfactionForms = false;
        });
    },
  },
};

</script>

<style lang="scss" scoped>


</style>