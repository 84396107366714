<template>
  <div style="padding: 10px;" class="vertical-spaced-container">
    <GFilterBar>
      <template #filter>
        <MonthSelector class="filter-item" @change="onSelectedMonthChanged" />

        <div v-if="canFilterByTechnician" class="filter-item">
          <div class="filter-item-title">
            Filter par technicien
          </div>
          <TechnicianSelect
            v-model="technicianId"
            :meOption="canFilterByHimself" />
        </div>
      </template>
    </GFilterBar>

    <div v-if="selectedTechnician == null">
      Commencez par sélectionner un technician
    </div>

    <div v-else>
      <div class="vertical-spaced-container">
        <div class="flow-spaced-container">
          <div class="card" style="width: 250px;">
            <p class="text-muted">
              Chiffre d'affaire HT sur le mois
            </p>
            <p style="font-size: 34px; font-weight: 500; margin-top: auto;">
              <LoaderView v-if="data == null" />
              <span v-else-if="data.total_turnover_excluding_tax != null">{{ data.total_turnover_excluding_tax }}€</span>
              <span v-else class="text-muted">Inconnu</span>
            </p>
          </div>

          <div class="card" style="width: 250px;">
            <p class="text-muted">
              Objectif de chiffre d'affaire HT moyen par jour de travail
            </p>
            <p style="font-size: 34px; font-weight: 500; margin-top: auto;">
              <LoaderView v-if="data == null" />
              <span v-else-if="data.goal_for_average_turnover_excluding_tax_per_working_day != null">{{ data.goal_for_average_turnover_excluding_tax_per_working_day }}€</span>
              <span v-else class="text-muted">Inconnu</span>
            </p>
          </div>

          <div class="card" style="width: 250px;">
            <p class="text-muted">
              Journée travaillées
            </p>
            <p style="font-size: 34px; font-weight: 500; margin-top: auto;">
              <LoaderView v-if="data == null" />
              <span v-else-if="data.nb_of_working_half_days != null">{{ data.nb_of_working_half_days / 2 }}</span>
              <span v-else class="text-muted">Inconnu</span>
            </p>
          </div>

          <div class="card" style="width: 250px;">
            <p class="text-muted">
              Objectif de chiffre d'affaire HT sur le mois
            </p>
            <p style="font-size: 34px; font-weight: 500; margin-top: auto;">
              <LoaderView v-if="data == null" />
              <span v-else-if="data.goal_for_total_turnover_excluding_tax_over_month != null">{{ data.goal_for_total_turnover_excluding_tax_over_month }}€</span>
              <span v-else class="text-muted">Inconnu</span>
            </p>
          </div>

          <div class="card" style="width: 250px;">
            <p class="text-muted">
              Chiffre d'affaire HT au dessus de l'objectif TODO faire un camembert
            </p>
            <p style="font-size: 34px; font-weight: 500; margin-top: auto;">
              <LoaderView v-if="data == null" />
              <span v-else-if="data.total_turnover_excluding_tax_over_goal != null">{{ data.total_turnover_excluding_tax_over_goal }}€</span>
              <span v-else class="text-muted">Inconnu</span>
            </p>
          </div>

          <div class="card" style="width: 250px;">
            <p class="text-muted">
              Bonus
            </p>
            <p style="font-size: 34px; font-weight: 500; margin-top: auto;">
              <LoaderView v-if="data == null" />
              <span v-else-if="data.bonus != null">{{ data.bonus }}€</span>
              <span v-else class="text-muted">Inconnu</span>
            </p>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <span class="title">Factures comptabilisées</span>
          </div>

          <div class="card-body">
            <LoaderView v-if="data == null" />

            <div v-else class="list">
              <div class="list-content">
                <table
                  v-if="data.invoices.length > 0"
                  style="width: 100%;"
                  cellspacing="0">
                  <thead>
                    <tr class="my-list-row">
                      <th>Référence</th>
                      <th>Créé le</th>
                      <th>Auteur</th>
                      <th>Client</th>
                      <th>Adresse de facturation</th>
                      <th>Nombre final d'intervention</th>
                      <th>Interventions déjà créées</th>
                      <th>Montant</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(invoice, index) in data.invoices"
                      :key="index"
                      class="my-list-row">
                      <td>
                        {{ invoice.ref }}
                      </td>
                      <td>
                        {{ $filters.dateStringToHumanizedDate(invoice.created_at) }}
                      </td>
                      <td>
                        {{ $filters.fullname(invoice.author) }}
                      </td>
                      <td>
                        <button
                          v-if="invoice.customer"
                          class="btn-link"
                          @click="$router.push({ name: 'customer', params: { id: invoice.customer.id }})">
                          {{ $filters.printCustomer(invoice.customer) }}
                        </button>
                      </td>
                      <td class="small">
                        {{ invoice.address.street_number }} {{ invoice.address.street }}<br>
                        {{ invoice.address.zipcode }} {{ invoice.address.city }} {{ invoice.address.country_code }}
                      </td>
                      <td>
                        <div v-if="invoice.final_number_of_interventions > 0" class="tooltip">
                          {{ invoice.final_number_of_interventions }} <i class="bi bi-eye" />
                          <span class="tooltip-text">
                            Le montant de la facture ({{ invoice.total_excl_tax }}€) est partagé en {{ invoice.final_number_of_interventions }} parts égales de {{ invoice.total_excl_tax / invoice.final_number_of_interventions }}€
                          </span>
                        </div>
                        <span v-else>
                          Aucune intervention<br>
                          Bonus alloué à l'auteur
                        </span>
                      </td>
                      <td>
                        <div
                          v-for="(intervention, interventionIndex) in invoice.interventions"
                          :key="interventionIndex">
                          <router-link :to="{ name: 'intervention', params: { id: intervention.id }}" style="margin-right: 3px;">
                            <span v-if="intervention.rdv_date">{{ $filters.dateStringToHumanizedDate(intervention.rdv_date) }} : </span>{{ intervention.location.street_number }} {{ intervention.location.street }} {{ intervention.location.city }}
                          </router-link>

                          <div v-if="intervention.nb_assigned_technicians > 0" class="tooltip">
                            {{ intervention.nb_assigned_technicians }} techs assignés <i class="bi bi-eye" />
                            <span class="tooltip-text">
                              Le montant de la facture alloué à cette intervention ({{ invoice.total_excl_tax / invoice.final_number_of_interventions }}€) est partagé en {{ intervention.nb_assigned_technicians }} parts égales de {{ invoice.total_excl_tax / invoice.final_number_of_interventions / intervention.nb_assigned_technicians }}€ entre :<br>
                              <span v-if="intervention.technician" style="white-space: nowrap;">- {{ intervention.technician.first_name }} {{ intervention.technician.last_name }}<br></span>
                              <span
                                v-for="(otherTechnician, index3) in intervention.other_technicians"
                                :key="index3"
                                style="white-space: nowrap;">
                                - {{ otherTechnician.first_name }} {{ otherTechnician.last_name }}<br>
                              </span>
                            </span>
                          </div>

                          <span v-else class="text-red">Aucun tech assigné</span>

                          <span v-if="intervention.rdv_date == null" class="text-red">Pas de date de rdv</span>
                          <span
                            v-else
                            :class="{ 'text-red': toGDate(intervention.rdv_date).year != year || toGDate(intervention.rdv_date).month != month }">
                            Bonus comptabilisé pour {{ toGDate(intervention.rdv_date).monthName() }}
                          </span>

                          <span v-if="intervention.nb_assigned_technicians == 0 || intervention.rdv_date == null" class="text-red">
                            <br>Aucun bonus comptabilisé !
                          </span>
                        </div>
                      </td>
                      <td>
                        Montant total HT : <strong>{{ invoice.total_excl_tax }}€</strong><br>
                        Montant HT aloué à {{ $filters.fullname(selectedTechnician) }} : <strong>{{ invoice.turnover_excluding_taxes }}€</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div v-else style="display: flex; justify-content: center;">
                  Aucune facture à comptabiliser
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <span class="title">Notes de crédit comptabilisées</span>
          </div>

          <div class="card-body">
            <LoaderView v-if="data == null" />

            <div v-else class="list">
              <div class="list-content">
                <table
                  v-if="data.credit_notes.length > 0"
                  style="width: 100%;"
                  cellspacing="0">
                  <thead>
                    <tr class="my-list-row">
                      <th>Référence</th>
                      <th>Créé le</th>
                      <th>Auteur</th>
                      <th>Client</th>
                      <th>Adresse</th>
                      <th>Montant</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(creditNote, index) in data.credit_notes"
                      :key="index"
                      class="my-list-row">
                      <td>
                        {{ creditNote.ref }}
                      </td>
                      <td>
                        {{ $filters.dateStringToHumanizedDate(creditNote.created_at) }}
                      </td>
                      <td>
                        {{ $filters.fullname(creditNote.author) }}
                      </td>
                      <td>
                        <button
                          v-if="creditNote.customer"
                          class="btn-link"
                          @click="$router.push({ name: 'customer', params: { id: creditNote.customer.id }})">
                          {{ $filters.printCustomer(creditNote.customer) }}
                        </button>
                      </td>
                      <td class="small">
                        {{ creditNote.address.street_number }} {{ creditNote.address.street }}<br>
                        {{ creditNote.address.zipcode }} {{ creditNote.address.city }} {{ creditNote.address.country_code }}
                      </td>
                      <td>
                        Montant HT déduit : <strong>-{{ creditNote.amount }}€</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div v-else style="display: flex; justify-content: center;">
                  Aucune note de crédit à déduire
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <span class="title">Eléments supplémentaires comptabilisées dans le chiffre d'affaire</span>
          </div>

          <div class="card-body">
            <LoaderView v-if="data == null" />

            <div v-else class="list">
              <div class="list-content">
                <table
                  v-if="data.extra_turnover_elements.length > 0"
                  style="width: 100%;"
                  cellspacing="0">
                  <thead>
                    <tr class="my-list-row">
                      <th>Date</th>
                      <th>Montant</th>
                      <th>Libellé</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(element, index) in data.extra_turnover_elements"
                      :key="index"
                      class="my-list-row">
                      <td>
                        {{ $filters.dateStringToHumanizedDate(element.date) }}
                      </td>
                      <td>
                        <strong>{{ element.amount }}€</strong>
                      </td>
                      <td>
                        {{ element.label }}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div v-else style="display: flex; justify-content: center;">
                  Aucun élément à afficher
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <span class="title">Eléments supplémentaires comptabilisées dans le bonus</span>
          </div>

          <div class="card-body">
            <LoaderView v-if="data == null" />

            <div v-else class="list">
              <div class="list-content">
                <table
                  v-if="data.extra_bonus_elements.length > 0"
                  style="width: 100%;"
                  cellspacing="0">
                  <thead>
                    <tr class="my-list-row">
                      <th>Date</th>
                      <th>Montant</th>
                      <th>Libellé</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(element, index) in data.extra_bonus_elements"
                      :key="index"
                      class="my-list-row">
                      <td>
                        {{ $filters.dateStringToHumanizedDate(element.date) }}
                      </td>
                      <td>
                        <strong>{{ element.amount }}€</strong>
                      </td>
                      <td>
                        {{ element.label }}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div v-else style="display: flex; justify-content: center;">
                  Aucun élément à afficher
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { api, uri } from "@/api";
import LoaderView from "@/components/LoaderView.vue";
import { GDate } from "g-date";
import { GFilterBar } from "g-list";
import MonthSelector from "@/components/MonthSelector.vue";
import { useMeStore } from "@/stores/me";
import { useTechniciansStore } from "@/stores/technicians";
import TechnicianSelect from "@/components/selects/TechnicianSelect.vue";

export default {
  name: "RewardsView",
  components: {
    LoaderView,
    GFilterBar,
    MonthSelector,
    TechnicianSelect,
  },
  data() {
    return {
      year: GDate.today().year,
      month: GDate.today().month,
      technicianId: useMeStore().meIsTechnician ? useMeStore().me.id : null,
      data: null,
      meStore: useMeStore(),
      techniciansStore: useTechniciansStore(),
    };
  },
  computed: {
    technicians() {
      return this.techniciansStore.getTechnicians;
    },
    selectedTechnician() {
      if (this.technicianId == null) {
        return null;
      }

      return this.technicians.find((technician) => technician.id == this.technicianId);
    },
    canFilterByTechnician() {
      return this.meStore.meIsAdmin || this.meStore.meIsSecretary || this.meStore.meIsManager;
    },
    canFilterByHimself() {
      return this.meStore.meIsTechnician;
    },
  },
  watch: {
    technicianId() {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.data = null;
      if (this.technicianId != null) {
        api.get(uri.bonus(this.year, this.month, this.technicianId))
          .then((response) => {
            this.data = response.data;
          });
      }
    },
    onSelectedMonthChanged(data) {
      this.year = data.year;
      this.month = data.month;
      this.refresh();
    },
    toGDate(dateISOString) {
      return GDate.parse(dateISOString.split("T")[0]);
    },
  },
};

</script>


<style lang="scss" scoped>

  .list {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 10px;
    .list-content {
      flex-grow: 1;

      table {
        thead {
          tr {
            th {
              text-align: left;
            }
          }
        }
      }
    }
  }

</style>
  